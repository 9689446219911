/**
 * Demo
 *
 * Stylesheet for demonstration purposes and development
 */

@import 'abstracts/mixins';
@import 'abstracts/variables';

/**
 * Container
 */
.demo-container,
%demo-container {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.demo-container-md {
  @extend %demo-container;
  max-width: 780px;
}

.demo-container-sm {
  @extend %demo-container;
  max-width: 520px;
}

.demo-container-lg {
  @extend %demo-container;
  max-width: 1160px;
}

/**
 * Header
 */
.demo-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #ddd;
  background-color: #eee;
}
.demo-title {
  margin-bottom: 0;
  font-size: 2.2rem;
}

/**
 * Sections
 */
.demo-section,
%demo-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.demo-section-dimmed {
  @extend %demo-section;
  background-color: #eee;
}

/**
 * Headings
 */
.demo-h2 {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #aaa;
  font-size: 1.6rem;

  &:not(:first-child) {
    margin-top: 3rem;
  }
}
.demo-h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}
.demo-h4 {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-size: 1rem;
  text-transform: uppercase;
}

/**
 * Symbol grid
 */
.demo-icon {
  display: inline-block;
  margin-right: 1rem;
  min-width: 10rem;
  text-align: center;
}

.demo-icon__holder {
  display: inline-block;
  font-size: 5rem;

  .symbol {
    border: 1px solid #eee;
  }
}


/**
 * Colors
 */
@mixin demo-color-classes($map) {
  @each $key, $color in $demo-colors {
    .demo-color-#{$key} {
      color: $color;
    }

    .demo-bg-#{$key} {
      background-color: $color;
    }
  }
}

@mixin demo-pseudo-swatches($map) {
  $idx: 1;

  @each $key, $color in $map {
    >:nth-child(#{$idx}) {
      display: inline-block;
      background-color: $color;

      &:before {
        content: '#{$key}';
      }

      &:after {
        content: '#{$color}';
      }
    }

    $idx: $idx + 1;
  }
}

$demo-colors: (
  'black': #000,
  'gray-dark': #323232,
  'gray': #ccc,
  'gray-light': #e0e0e0,
  'gray-lighter': #efeff0,
  'gray-lightest': #f7f7f7,
  'white': #fff,
  'blue-dark': #004c93,
  'blue-strong': #277CB4,
  'blue': #009fe3,
  'blue-soft': #83d0f5,
  'green-gray': #657676,
  'dove': #666666,
  'green': #008539,
  'green-lime': #9ac331,
  'yellow': #ffe500,
  'orange': #f7a70a,
  'red': #d1422f,
  'pink': #e46090,
  'magenta': #963683,
  'brand-primary': #004c93,
  'brand-success': #009640,
  'brand-danger': #d1422f,
);

@include demo-color-classes($demo-colors);

.demo-pseudo-swatches {
  > * {
    display: none;
    position: relative;
    vertical-align: middle;
    width: 10rem;
    height: 10rem;
    margin-right: 1rem;
    margin-top: 1rem !important;

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      padding: .25rem;
      white-space: nowrap;
      line-height: 1.2;
      background-color: #fff;
      font-size: type-scale(-1);
    }

    &:before {
      top: 1rem;
    }

    &:after {
      bottom: 1rem;
    }
  }

  @include demo-pseudo-swatches($demo-colors);
}
